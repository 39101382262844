import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import { Header } from 'components/header'
import Main from 'components/main'
import Footer from 'components/footer'
import ProjectHeader from 'components/projectHeader'
import ImageBlock from 'components/imageBlock'
import TextBlock from 'components/textBlock'
// import ThreeColumn from 'components/threeColumn'
import ProjectFooter from 'components/projectFooter'
import { graphql } from 'gatsby'

const DesignSystemsPage = ({ data, location }) => (
  <Layout location={location}>
    <Helmet>
      <title>{data.designsystemsJson.metaTitle}</title>
    </Helmet>
    <Header />
    <Main>
      <article>
        <ProjectHeader
          heading={data.designsystemsJson.heading}
          subheading={data.designsystemsJson.subheading}
          disciplines={data.designsystemsJson.disciplines}
        />
        <ImageBlock src={data.designsystemsJson.projectImages.imageOne} alt={data.designsystemsJson.metaTitle} />
        <TextBlock copy={data.designsystemsJson.copy} />
        <ImageBlock src={data.designsystemsJson.projectImages.imageTwo} alt={data.designsystemsJson.metaTitle} />
        <ImageBlock src={data.designsystemsJson.projectImages.imageThree} alt={data.designsystemsJson.metaTitle} />
        <ImageBlock src={data.designsystemsJson.projectImages.imageFour} alt={data.designsystemsJson.metaTitle} />
      </article>
      <ProjectFooter link={data.designsystemsJson.nextLink} />
    </Main>
    <Footer />
  </Layout>
)

DesignSystemsPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default DesignSystemsPage

export const query = graphql`
  query DesignSystemsPageQuery {
    designsystemsJson {
      metaTitle
      metaDescription
      heading
      subheading
      disciplines
      copy
      nextLink
      projectImages {
        imageOne {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageTwo {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageThree {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
        imageFour {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1032, placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`
